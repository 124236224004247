<template>
  <div>
    <h1 class="text-center">
      COMING SOON
    </h1>
  </div>
</template>
<script>
export default {
  components: {},
}
</script>
